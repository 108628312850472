.carousel {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    
  }
  
  .carousel-images {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.5s ease-in-out;
    max-width: 100%;
    height: 50vh;
  }
  
  .carousel-image {
    width: 100%;
    height: auto;
    display: none;
  }
  
  .carousel-image.active {
    display: block;
  }
  
  .carousel-control {
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.3s ease;
    user-select: none;
  }
  
  .prev {
    left: 0;
    border-radius: 0 3px 3px 0;
  }
  
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  .carousel-control:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  